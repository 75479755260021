import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Page from './components/Page';
import HomePage from './pages/HomePage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Page />}>
          <Route index element={<HomePage />} />

          <Route path="*" element={<HomePage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
