/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-scroll';
import styled, { css } from 'styled-components';
import { colors } from '../../styles/variables';
import { TextGradient, StyledMediumText } from '../../styles/commonStyles';
import { onDevice, optimizedBgImage } from '../../styles/mixins';
import { DeviceType } from '../../types/types';
import Bg from '../../assets/png/backgrounds/Bg-top-mobile.png';
import BgW from '../../assets/webp/backgrounds/Bg-top-mobile.webp';

type Props = {
  closeMenu: () => void;
};

const StyledMenu = styled.aside`
  display: none;

  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-color: black;
  background-image: url(${Bg});
  ${optimizedBgImage(BgW)}
  z-index: 999;
  overflow-y: hidden;

  ${onDevice(
    DeviceType.mobile,
    css`display: block;`,
  )}
`;

const StyledTopBar = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 96px;
`;

const StyledLogo = styled(Link)`
  font-family: "MuseoModerno", Arial, sans-serif;
  font-size: 40px;
  text-decoration: none;
  text-transform: uppercase;
  ${TextGradient}
  color: transparent;
`;

const StyledButton = styled.button`
  position: absolute;
  right: 32px;
  height: fit-content;
  transform: translateY(-10%);
  font-family: "IBM Plex Sans", Arial, sans-serif;
  font-size: 40px;
  font-weight: 400;
  color: ${colors.textMain};
  background: transparent;
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
`;

const StyledNavItem = styled(Link)`
  ${StyledMediumText}
  font-size: 32px;
  color: ${colors.textMain};
  ${TextGradient}

  &:active {
    color: transparent;
  }
`;

function MenuMobile({ closeMenu }: Props) {
  const linkOptions = useMemo(() => ({
    spy: true,
    smooth: true,
    offset: -90,
    duration: 500,
    onClick: closeMenu,
  }), []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <StyledMenu>
      <StyledTopBar>
        <StyledLogo
          to="title"
          {...linkOptions}
        >
          siema
        </StyledLogo>

        <StyledButton type="button" onClick={closeMenu}>x</StyledButton>
      </StyledTopBar>

      <StyledNav>
        <StyledNavItem
          to="about"
          {...linkOptions}
        >
          About
        </StyledNavItem>

        <StyledNavItem
          to="services"
          {...linkOptions}
        >
          Services
        </StyledNavItem>

        <StyledNavItem
          to="why-us"
          {...linkOptions}
        >
          Why us
        </StyledNavItem>

        <StyledNavItem
          to="testimonials"
          {...linkOptions}
        >
          Testimonials
        </StyledNavItem>

        <StyledNavItem
          to="contacts"
          {...linkOptions}
        >
          Contact
        </StyledNavItem>
      </StyledNav>
    </StyledMenu>
  );
}

export default MenuMobile;
