import { RuleSet, css } from 'styled-components';
import { DeviceType } from '../types/types';

export const onDevice = (device: DeviceType, styles: RuleSet<object>
| ((props: any) => ReturnType<typeof css>)) => (
  typeof styles === 'object'
    ? css`@media (max-width: ${device}) {
      ${styles}
    }`
    : css`
    @media (max-width: ${device}) {
      ${(props) => `${styles(props)}`}
    }
  `
);

export const optimizedBgImage = (webpImage: string) => css`
  @supports (background-image: image-set(${webpImage} type("image/webp"))) {
    background-image: url(${webpImage});
  }
`;
