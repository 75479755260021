import React from 'react';
import { CircleLoader } from 'react-spinners';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
`;

function Loader() {
  return (
    <StyledContainer>
      <CircleLoader size={150} color="#FFFFFF" loading />
      {/* <CircleLoader size={150} color="white" loading /> */}
    </StyledContainer>
  );
}

export default Loader;
