import React, { useState } from 'react';
import { Link } from 'react-scroll';
import styled, { css } from 'styled-components';
import { colors } from '../../styles/variables';
import { onDevice } from '../../styles/mixins';
import { DeviceType } from '../../types/types';
import { TextGradient } from '../../styles/commonStyles';
import Logo from '../../assets/png/Logo.svg';
import LogoMobile from '../../assets/png/LogoMobile.svg';
import Menu from '../../assets/png/Menu-icon.svg';
import MenuMobile from '../MenuMobile';

const StyledHeader = styled.header`
  position: fixed;
  display: flex;
  align-items: center;
  padding-inline: 80px;
  width: 100%;
  height: 90px;
  background-color: rgba(4, 8, 22, 0.8);
  backdrop-filter: blur(1px);
  z-index: 3;

  ${onDevice(
    DeviceType.mobile,
    css`
      justify-content: center;
      padding-inline: 24px;
      height: 66px;
    `,
  )}
`;

const LogoLink = styled(Link)`
  align-self: center;
  text-decoration: none;
  cursor: pointer;

  ${onDevice(
    DeviceType.mobile,
    css`font-size: 24px;`,
  )}
`;

const LogoImg = styled.img`
  ${onDevice(
    DeviceType.mobile,
    css`display: none;`,
  )}
`;

const LogoImgMobile = styled.img`
  display: none;

  ${onDevice(
    DeviceType.mobile,
    css`display: unset;`,
  )}
`;

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-left: 75px;

  ${onDevice(
    DeviceType.mobile,
    css`display: none;`,
  )}
`;

const StyledNavItem = styled(Link)`
  font-family: "IBM Plex Sans", Arial, sans-serif;
  font-weight: 400;
  text-decoration: none;
  text-transform: capitalize;
  color: ${colors.textInactive};
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  ${TextGradient}

  &:hover {
    color: transparent;
  }

  &.active {
    font-weight: 500;
    color: ${colors.textMain};
  }
`;

const StyledSpan = styled.span`
  text-transform: none;
  color: inherit;
`;

const MenuIcon = styled.button`
  display: none;

  ${onDevice(
    DeviceType.mobile,
    css`
      position: absolute;
      top: 50%;
      left: 24px;
      transform: translateY(-50%);
      display: block;
      background-image: url(${Menu});
      background-repeat: no-repeat;
      background-color: transparent;
      width: 23px;
      height: 23px;
    `,
  )}
`;

function Header() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  return (
    <StyledHeader>
      <LogoLink to="title" spy smooth offset={-90} duration={500}>
        <LogoImg src={Logo} alt="Siema logo" />

        <LogoImgMobile src={LogoMobile} alt="Siema logo" />
      </LogoLink>

      <StyledNav>
        <StyledNavItem
          to="about"
          spy
          smooth
          offset={-90}
          duration={500}
        >
          about
        </StyledNavItem>

        <StyledNavItem
          to="services"
          spy
          smooth
          offset={-90}
          duration={500}
        >
          services
        </StyledNavItem>

        <StyledNavItem
          to="why-us"
          spy
          smooth
          offset={-90}
          duration={500}
        >
          why
          {' '}
          <StyledSpan>us</StyledSpan>
        </StyledNavItem>

        <StyledNavItem
          to="testimonials"
          spy
          smooth
          offset={-90}
          duration={500}
        >
          testimonials
        </StyledNavItem>

        <StyledNavItem
          to="contacts"
          spy
          smooth
          offset={-90}
          duration={500}
        >
          contact
        </StyledNavItem>
      </StyledNav>

      <MenuIcon aria-label="Menu" type="button" onClick={() => setIsMenuVisible(true)} />

      {isMenuVisible && <MenuMobile closeMenu={() => setIsMenuVisible(false)} />}
    </StyledHeader>
  );
}

export default Header;
