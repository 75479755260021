import React, { useEffect } from 'react';
import { Link } from 'react-scroll';
import styled, { css } from 'styled-components';
import { colors, metrics } from '../../../styles/variables';
import {
  StyledTitle, StyledRegularText, StyledMediumText, Picture,
} from '../../../styles/commonStyles';
import { onDevice } from '../../../styles/mixins';
import { DeviceType } from '../../../types/types';
import Moon from '../../../assets/png/Moon-top.png';
import MoonW from '../../../assets/webp/Moon-top.webp';
import MoonMobile from '../../../assets/png/Moon-top-mobile.png';
import MoonMobileW from '../../../assets/webp/Moon-top-mobile.webp';
import Ellipse from '../../../assets/png/ellipses/Ellipse1.png';
import EllipseW from '../../../assets/webp/ellipses/Ellipse1.webp';

const StyledSection = styled.section`
  position: relative;
  padding-top: 228px;
  width: 100%;
  contain: style;

  ${onDevice(
    DeviceType.mobile,
    css`padding-top: 178px;`,
  )}
`;

const StyledContainer = styled.div`
  position: relative;
  max-width: 100%;
  z-index: 1;

  ${onDevice(
    DeviceType.mobile,
    css`width: 100%;`,
  )}
`;

const StyledTextContainer = styled.div`
  background-image: ${colors.gradientSecondary};
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  background-clip: text;

  ${onDevice(
    DeviceType.mobile,
    css`width: 100%;`,
  )}
`;

const StyledH1 = styled.h1`
  ${StyledTitle}
  width: 687px;
  max-width: 100%;

  ${onDevice(
    DeviceType.mobile,
    css`
      width: 100%;
      max-width: 480px;
      font-size: 48px;
    `,
  )}
`;

const StyledSpan = styled.span`
  color: transparent;
`;

const StyledText = styled.p`
  ${StyledRegularText}
  margin-top: 24px;
  width: 544px;
  color: ${colors.textInactive};

  ${onDevice(
    DeviceType.mobile,
    css`
      margin-top: 32px;
      width: 100%;
      max-width: 480px;
      font-family: "Inter", Arial, sans-serif;
      font-size: 24px;
    `,
  )}
`;

const StyledBtnContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  width: 267px;
  height: 48px;
  border-radius: ${metrics.borderRadiusPrimary};
  background: ${colors.gradientPrimary};

  ${onDevice(
    DeviceType.mobile,
    css`
      margin-top: 32px;
      width: 246px;
    `,
  )}
`;

const StyledBtnBg = styled.div`
  position: absolute;
  width: 265px;
  height: 46px;
  background-color: black;
  border-radius: ${metrics.borderRadiusPrimary};

  ${onDevice(
    DeviceType.mobile,
    css`width: 244px;`,
  )}
`;

const StyledButton = styled(Link)`
  position: absolute;
  padding: 11px 46px;
  border-radius: ${metrics.borderRadiusPrimary};

  ${StyledMediumText}

  color: ${colors.textMain};
  background-color: ${colors.btnBg};
  cursor: pointer;

  ${onDevice(
    DeviceType.mobile,
    css`padding-inline: 36px;`,
  )}
`;

const StyledEllipse = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100vw;
  pointer-events: none;
`;

const StyledPicture = styled.picture`
  ${onDevice(
    DeviceType.mobile,
    css`display: none;`,
  )}

  ${Picture}
`;

const StyledMoon = styled.img`
  position: absolute;
  top: 0;
  right: -80px;
  width: 75.7%;
  height: auto;
  z-index: 0;
  pointer-events: none;
  pointer-events: none;
`;

const StyledPictureMobile = styled.picture`
  display: none;

  ${onDevice(
    DeviceType.mobile,
    css`
      display: block;
    `,
  )}

  ${Picture}
`;

const StyledMoonMobile = styled.img`
  display: block;
  position: absolute;
  top: 0;
  right: -24px;
  width: 100%;
  height: auto;
  z-index: 0;
  pointer-events: none;
`;

function TitleSection() {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = MoonMobileW;
    document.head.appendChild(link);
  }, []);

  return (
    <StyledSection id="title">
      <StyledContainer>
        <StyledTextContainer>
          <StyledH1>
            <StyledSpan>Siema: </StyledSpan>

            Where Tech Expertise Meets Global Innovation
          </StyledH1>
        </StyledTextContainer>

        <StyledText>
          Elevate your software development with highly skilled Ukrainian
          {' '}
          developers and a dedicated Israeli support team.
        </StyledText>

        <StyledBtnContainer>
          <StyledBtnBg />

          <Picture>
            <source srcSet={EllipseW} type="image/webp" />
            <StyledEllipse
              src={Ellipse}
              alt="Button Shadow"
              fetchPriority="high"
            />
          </Picture>

          <StyledButton to="contacts" spy smooth offset={-90} duration={500}>
            Get a Free Consultation
          </StyledButton>
        </StyledBtnContainer>
      </StyledContainer>

      <StyledPicture>
        <source srcSet={MoonW} type="image/webp" />
        <StyledMoon
          src={Moon}
          alt="Moon"
        />
      </StyledPicture>

      <StyledPictureMobile>
        <source srcSet={MoonMobileW} type="image/webp" />
        <StyledMoonMobile
          src={MoonMobile}
          alt="Moon"
          fetchPriority="high"
        />
      </StyledPictureMobile>
    </StyledSection>
  );
}

export default TitleSection;
