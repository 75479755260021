import React from 'react';
import styled, { css } from 'styled-components';
import {
  StyledRegularText, StyledSemiBoldText, StyledTitle, TextGradient, StyledBackground,
} from '../../../styles/commonStyles';
import { colors } from '../../../styles/variables';
import { onDevice, optimizedBgImage } from '../../../styles/mixins';
import { logos } from '../../../utils/data';
import { DeviceType } from '../../../types/types';
import Ellipse from '../../../assets/png/ellipses/Ellipse2.png';
import EllipseW from '../../../assets/webp/ellipses/Ellipse2.webp';
import EllipseMobile1 from '../../../assets/png/ellipses/Ellipse1-mobile.png';
import EllipseMobile2 from '../../../assets/png/ellipses/Ellipse2-mobile.png';
import EllipseMobile3 from '../../../assets/png/ellipses/Ellipse3-mobile.png';
import EllipseMobile1W from '../../../assets/webp/ellipses/Ellipse1-mobile.webp';
import EllipseMobile2W from '../../../assets/webp/ellipses/Ellipse2-mobile.webp';
import EllipseMobile3W from '../../../assets/webp/ellipses/Ellipse3-mobile.webp';
import Machine from '../../../assets/png/Machine.png';
import MachineW from '../../../assets/webp/Machine.webp';

const StyledSection = styled.section`
  position: relative;
  margin-top: 226px;
  contain: style;

  &::before, &::after {
    ${StyledBackground}
  }

  &::before {
    top: 252px;
    left: -48px;
    top: 50%;
    transform: translateY(-30%);
    width: 29.14%;
    height: calc(100vw * 0.243);
    background-image: url(${Machine});
    ${optimizedBgImage(MachineW)}
  }

  &::after {
    left: -80px;
    top: 50%;
    transform: translateY(-50%);
    width: 69.3%;
    height: 100%;
    height: calc(100vw * 0.6);
    background-image: url(${Ellipse});
    ${optimizedBgImage(EllipseW)}
  }

  ${onDevice(
    DeviceType.mobile,
    css`
      margin-top: 444px;

      &::before {
        top: -246px;
        left: calc((100% - 264px) * 0.57);
        transform: translateY(0);
        width: 282px;
        height: 264px;
      }

      &::after {
        top: -40%;
        left: -24px;
        width: calc(100% + 48px);
        height: calc(100vw * 2.08);
        transform: translateY(0%);
        background-image: url(${EllipseMobile1});
        ${optimizedBgImage(EllipseMobile1W)}
      }
    `,
  )}
`;

const StyledH1 = styled.h1`
  margin-inline: auto;
  ${StyledTitle}
  text-align: center;
  max-width: 1072px;

  background-image: ${colors.gradientSecondary};
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  background-clip: text;

  ${onDevice(
    DeviceType.mobile,
    css`
      position: relative;
      max-width: 540px;
      font-size: 40px;
      z-index: 1;
    `,
  )}
`;

const StyledSpan = styled.span`
  font-weight: inherit;
  color: transparent;
`;

const StyledText = styled.p`
    position: relative;
  ${StyledRegularText}
  margin: 56px auto 0;
  width: 564px;
  text-align: center;
  color: ${colors.textMain};
  z-index: 1;

  ${onDevice(
    DeviceType.mobile,
    css`
      margin-top: 32px;
      width: 100%;
    `,
  )}
`;

const StyledH4 = styled.h4`
  position: relative;
  margin: 119px auto 0;
  width: fit-content;

  text-align: center;
  font-family: "Inter", Arial, sans-serif;
  ${StyledSemiBoldText}

  ${TextGradient}
  background-clip: text;

  ${onDevice(
    DeviceType.mobile,
    css`
      margin-top: 146px;
      width: 100%;
      font-size: 24px;
    `,
  )}
`;

const StyledSmallSpan = styled.span`
  color: transparent;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 48px;
  margin-top: 56px;

  ${onDevice(
    DeviceType.mobile,
    css`
      position: relative;
      flex-direction: column;
      gap: 40px;
      margin-top: 40px;

      &::before, &::after {
        ${StyledBackground}
        left: -24px;
        width: calc(100% + 48px);
        background-size: cover;
      }

      &::before {
        background-image: url(${EllipseMobile2});
        ${optimizedBgImage(EllipseMobile2W)}
        top: -24.69%;
        transform: translateY(-25%);
        height: calc(100vw * 2.39);
      }

      &::after {
        background-image: url(${EllipseMobile3});
        ${optimizedBgImage(EllipseMobile3W)}
        top: 50%;
        transform: translateY(-25%);
        height: calc(100vw * 2.39);
        z-index: 2;
      }
    `,
  )}
`;

const StyledIcon = styled.img`
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);

  &:first-of-type {
    align-self: flex-end;

    ${onDevice(
    DeviceType.mobile,
    css`align-self: center`,
  )}
  }
`;

function AboutSection() {
  return (
    <StyledSection id="about">
      <StyledH1>
        <StyledSpan>Siema: </StyledSpan>
        Code the Future, Global Reach, Local Touch.
      </StyledH1>

      <StyledText>
        2018: Siema launched, not as code-writers, but as architects of digital galaxies.
        We weave the cosmic brilliance of Ukrainian developers with the grounded wisdom of our
        Israeli team.This interstellar fusion empowers businesses to boldly explore new frontiers,
        achieving groundbreaking results, one line of code at a time. Join us and chart your course
        through the digital cosmos with Siema as your guide.
      </StyledText>

      <StyledH4>
        Providing power to the world&apos;s best product teams.
        <br />
        <StyledSmallSpan>From next-generation startups to established businesses</StyledSmallSpan>
      </StyledH4>

      <StyledLogoContainer>
        {logos.map(({ icon, alt }) => (
          <StyledIcon
            src={icon}
            alt={alt}
            key={icon}
          />
        ))}
      </StyledLogoContainer>
    </StyledSection>
  );
}

export default AboutSection;
