import styled, { css } from 'styled-components';
import { colors } from './variables';

export const StyledTitle = css`
  line-height: 110%;
  letter-spacing: -0.05em;
  font-family: "Inter", Arial, sans-serif;
  font-size: 80px;
  font-weight: 700;
`;

export const StyledRegularText = css`
  line-height: 150%;
  font-family: "IBM Plex Sans", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
`;

export const StyledMediumText = css`
  line-height: 150%;
  font-family: "IBM Plex Sans", Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
`;

export const StyledH1Medium = css`
  line-height: 130%;
  font-family: "Inter", Arial, sans-serif;
  font-size: 48px;
  font-weight: 700;
`;

export const StyledSemiBoldText = css`
  line-height: 150%;
  font-size: 26px;
  font-weight: 600;
`;

export const TextGradient = css`
  background-image: ${colors.gradientPrimary};
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
`;

export const StyledBackground = css`
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
`;

export const Picture = styled.picture`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const mapStyles = [
  { elementType: 'geometry', stylers: [{ color: colors.mapPrimary }] },
  { elementType: 'labels.text.fill', stylers: [{ color: colors.mapText }] },
  { elementType: 'labels.text.stroke', stylers: [{ visibility: 'off' }] },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: colors.mapRoad }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: colors.mapHighWayStroke }],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{ color: colors.mapWater }],
  },
];
