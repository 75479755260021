import Html from '../assets/png/techIcons/firstGroup/Html.png';
import Css from '../assets/png/techIcons/firstGroup/Css.png';
import Js from '../assets/png/techIcons/firstGroup/Js.png';
import React from '../assets/png/techIcons/firstGroup/React.png';
import Angular from '../assets/png/techIcons/firstGroup/Angular.png';
import Vue from '../assets/png/techIcons/firstGroup/Vue.png';
import Python from '../assets/png/techIcons/firstGroup/Python.png';
import Java from '../assets/png/techIcons/firstGroup/Java.png';
import Js2 from '../assets/png/techIcons/firstGroup/Js2.png';

import Php from '../assets/png/techIcons/secondGroup/Php.png';
import CS from '../assets/png/techIcons/secondGroup/C#.png';
import Ruby from '../assets/png/techIcons/secondGroup/Ruby.png';
import Aws from '../assets/png/techIcons/secondGroup/Aws.png';
import Azure from '../assets/png/techIcons/secondGroup/Azure.png';
import Cloud from '../assets/png/techIcons/secondGroup/Cloud.png';
import Docker from '../assets/png/techIcons/secondGroup/Docker.png';
import Kubernetes from '../assets/png/techIcons/secondGroup/Kubernetes.png';
import Figma from '../assets/png/techIcons/secondGroup/Figma.png';

import logo1 from '../assets/png/logos/logo1.png';
import logo2 from '../assets/png/logos/logo2.png';
import logo3 from '../assets/png/logos/logo3.png';
import logo4 from '../assets/png/logos/logo4.png';
import logo5 from '../assets/png/logos/logo5.png';
import logo6 from '../assets/png/logos/logo6.png';
import logo7 from '../assets/png/logos/logo7.png';

import { Testimonial } from '../types/types';

export const MENU = {
  Company: ['How it Works', 'Features', 'Docs', 'Blog', 'About'],
  Resource: ['Docs', 'Forum', 'Careers', 'Templates', 'Feedback'],
  Legal: ['Terms of Service', 'Privacy Policy', 'Guidelines', 'Fair Use Policy', 'Feedback'],
  Support: ['FAQs', 'Call Center', 'Maintance', 'Status'],
  Platform: ['Team', 'Security', 'Roadmap', 'Features', 'Enterprise'],
};

export const logos = [
  { icon: logo1, alt: 'Agam' },
  { icon: logo2, alt: 'Intelichain' },
  { icon: logo3, alt: 'Israel Electric Company' },
  { icon: logo4, alt: 'Kempinski' },
  { icon: logo5, alt: 'Rivulis' },
  { icon: logo6, alt: 'AMG קבוצת' },
  { icon: logo7, alt: 'Manna Irrigation' },
];

export const techIcons = [
  { icon: Html, alt: 'HTML' },
  { icon: Css, alt: 'CSS' },
  { icon: Js, alt: 'JavaScript' },
  { icon: React, alt: 'React' },
  { icon: Angular, alt: 'Angular' },
  { icon: Vue, alt: 'Vue' },
  { icon: Python, alt: 'Python' },
  { icon: Java, alt: 'Java' },
  { icon: Js2, alt: 'JavaScript' },
  { icon: Php, alt: 'PHP' },
  { icon: CS, alt: 'C#' },
  { icon: Ruby, alt: 'Ruby' },
  { icon: Aws, alt: 'AWS' },
  { icon: Azure, alt: 'Azure' },
  { icon: Cloud, alt: 'Cloud' },
  { icon: Docker, alt: 'Docker' },
  { icon: Kubernetes, alt: 'Kubernetes' },
  { icon: Figma, alt: 'Figma' },
];

export const testimonials: Testimonial[] = [
  {
    id: 1,
    userName: 'Leslie Alexander',
    userRole: 'Community',
    userPhoto: '',
    testimonialText: 'Circle is being used for my project, and the team has been very helpful. thanks, are there any new Tools you\'ve tried this week?',
    rating: 5,
  },
  {
    id: 2,
    userName: 'Dianne Russell',
    userRole: 'Community',
    userPhoto: '',
    testimonialText: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
    rating: 5,
  },
  {
    id: 3,
    userName: 'Darrell Steward',
    userRole: 'Community',
    userPhoto: '',
    testimonialText: 'Making your own mobile app is currently pretty easy for me. Thank you, with the aid of Circle assistive technologies!',
    rating: 5,
  },
  {
    id: 4,
    userName: 'Leslie Alexander',
    userRole: 'Community',
    userPhoto: '',
    testimonialText: 'Circle is being used for my project, and the team has been very helpful. thanks, are there any new Tools you\'ve tried this week?',
    rating: 5,
  },
  {
    id: 5,
    userName: 'Dianne Russell',
    userRole: 'Community',
    userPhoto: '',
    testimonialText: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
    rating: 5,
  },
  {
    id: 6,
    userName: 'Darrell Steward',
    userRole: 'Community',
    userPhoto: '',
    testimonialText: 'Making your own mobile app is currently pretty easy for me. Thank you, with the aid of Circle assistive technologies!',
    rating: 5,
  },
];

export const servicesCards = [
  {
    title: 'Web Development',
    text: 'Unleash the pixel-perfect magic of conversion-driven websites. Watch your vision transform into captivating experiences that captivate and convert.',
  },
  {
    title: 'Mobile Development',
    text: 'Future-proof your mobile presence with seamless connections across all devices. No matter the platform, ensure a frictionless experience that keeps your users engaged.',
  },
  {
    title: 'Custom Software Development',
    text: 'Dream it, build it, scale it – the power is yours. Our bespoke software solutions are meticulously crafted to match your vision, adapting and evolving alongside your journey.',
  },
  {
    title: 'Cloud Solutions',
    text: 'Tap into the cloud\'s full potential with agility, security, and freedom at your fingertips. Unleash innovation and unlock endless possibilities for your business.',
  },
  {
    title: 'UX/UI Design',
    text: 'Go beyond beautiful. Our UX/UI design experts blend artistry with technical prowess, creating interfaces that are not just visually stunning, but also intuitive and delightful to use.',
  },
  {
    title: 'Project Management',
    text: 'Our expert project management team guides your project with precision, ensuring smooth execution, clear communication, and timely delivery. ',
  },
];

export const whyUsCards = [
  {
    title: 'Unparalleled Expertise & Global Reach:',
    text: `Harness the brilliance: Leverage a global network of highly skilled Ukrainian developers at competitive rates.
    Diverse talent pool: Access experts in various technologies and frameworks to fit your project needs.
    Expand your reach: Go global with development teams spanning different time zones and cultural understanding.`,
    contactsText: 'Get a free consultation today!',
  },
  {
    title: 'Communication & Local Understanding:',
    text: `Bridge the gap: Benefit from a dedicated Israeli support team for clear communication and cultural understanding.
    Project clarity: Enjoy transparent communication and efficient project management throughout the development cycle.
    Peace of mind: Build your software with confidence knowing you have a reliable partner by your side.`,
    contactsText: 'Contact for a personal approach.',
  },
  {
    title: 'Proven Track Record & Innovation:',
    text: `See the results: Explore our diverse portfolio of successful projects across various industries.
    Stay ahead of the curve: Access cutting-edge technologies and agile development practices.
    Unlock your potential: Transform your business with innovative and future-proof solutions.`,
    contactsText: 'Start your project now!',
  },
  {
    title: 'Agile Development, Your Vision First:',
    text: `Tailored solutions: We adapt to your specific needs and budget, creating custom software that fits your vision.
    Open communication: Enjoy transparency and collaboration throughout the entire development process.
    Your success is our priority: We partner with you to achieve your goals and exceed expectations.`,
    contactsText: 'Request a custom quote today!',
  },
];
