import React, { Suspense, lazy } from 'react';
import TitleSection from '../../components/HomePage-components/TitleSection';
import AboutSection from '../../components/HomePage-components/AboutSection';
import Loader from '../../components/Loader';

const CollabSection = lazy(() => import('../../components/HomePage-components/CollabSection'));
const ServicesSection = lazy(() => import('../../components/HomePage-components/ServicesSection'));
const TechArsenalSection = lazy(() => import('../../components/HomePage-components/TechArsenalSection'));
const WhyUsSection = lazy(() => import('../../components/HomePage-components/WhyUsSection'));

// const TestimonialsSection =
// lazy(() => import('../../components/HomePage-components/TestimonialsSection'));

const ContactsSection = lazy(() => import('../../components/HomePage-components/ContactsSection'));

function HomePage() {
  return (
    <>
      <TitleSection />

      <AboutSection />

      <Suspense fallback={<Loader />}>
        <CollabSection />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <ServicesSection />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <TechArsenalSection />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <WhyUsSection />
      </Suspense>

      {/* <Suspense fallback={<Loader />}>
        <TestimonialsSection />
      </Suspense> */}

      <Suspense fallback={<Loader />}>
        <ContactsSection />
      </Suspense>
    </>
  );
}

export default HomePage;
