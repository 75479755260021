export const colors = {
  mainBg: '#020411',
  btnBg: 'rgba(0, 0, 0, 0.45)',
  collabBg: 'rgba(21, 25, 37, 0.4)',
  textMain: '#FFFFFF',
  textInactive: '#9D9D9D',
  gradientSecondary: 'linear-gradient(to right, #6A11CB, #2575FC)',
  gradientPrimary: 'linear-gradient(to right, #88D3CE, #6E45E2)',
  mapPrimary: '#323432',
  mapText: '#b2b2b2',
  mapRoad: '#454545',
  mapHighWayStroke: '#2b2b2b',
  mapWater: '#A9A9A9',
  cardBg: '#151925',
  contactsTextMain: '#E1E1E1',
  contactsBorder: '#5A5A5A',
  contactsBtnText: '#000000',
  mapAddressText: '#353535',
  mapAddressTextBg: 'rgba(255, 255, 255, 0.78)',
};

export const metrics = {
  borderRadiusPrimary: '8px',
};
