import React, { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Header from '../Header';
import Loader from '../Loader';
import { colors } from '../../styles/variables';
import { onDevice } from '../../styles/mixins';
import { DeviceType } from '../../types/types';
import BgTop from '../../assets/png/backgrounds/Bg-top.png';
import BgTopW from '../../assets/webp/backgrounds/Bg-top.webp';
import BgCenter from '../../assets/png/backgrounds/Bg-center.png';
import BgBottom from '../../assets/png/backgrounds/Bg-bottom.png';
import BgBottomW from '../../assets/webp/backgrounds/Bg-bottom.webp';
import BgMobile from '../../assets/png/backgrounds/Bg-mobile.png';
import BgTopMobile from '../../assets/png/backgrounds/Bg-top-mobile.png';
import BgTopMobileW from '../../assets/webp/backgrounds/Bg-top-mobile.webp';

const Footer = lazy(() => import('../Footer'));

const StyledPage = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  padding-inline: 80px;
  min-height: 100vh;
  background-color: ${colors.mainBg};

  background-image: url(${BgTop}), url(${BgCenter}), url(${BgBottom});
  background-position: top, center 1229px, bottom;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: contain, contain, contain;

  @supports (background-image: image-set("${BgTopW}" type("image/webp"))) {
    background-image: url(${BgTopW}), url(${BgCenter}), url(${BgBottomW});
  }

  ${onDevice(
    DeviceType.mobile,
    css`
      padding-inline: 24px;
      background-image: url(${BgMobile}), url(${BgTopMobile});
      background-position: top, top;
      background-repeat: no-repeat, no-repeat;
      background-size: contain, contain;

      @supports (background-image: image-set("${BgTopW}" type("image/webp"))) {
        background-image: url(${BgMobile}), url(${BgTopMobileW});
      }
    `,
  )}
`;

function Page() {
  return (
    <>
      <Header />

      <StyledPage>
        <main>
          <Outlet />
        </main>

        <Suspense fallback={<Loader />}>
          <Footer />
        </Suspense>
      </StyledPage>
    </>
  );
}

export default Page;
