export interface InfoCard {
  title: string;
  text: string;
  contactsText?: string;
}

export interface Testimonial {
  id: number;
  userName: string;
  userRole: string;
  userPhoto: string | null;
  testimonialText: string;
  rating: number;
}

export enum MarginType {
  services = 'services',
  whyUs = 'whyUs',
}

export enum MarginValues {
  services = '108px',
  whyUs = '72px',
  servicesMobile = '24px',
  whyUsMobile = '40px',
}

export enum DeviceType {
  mobile = '768px',
  tablet = '1023px',
}
